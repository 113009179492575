/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { getToolTipID } from '../ToolTipHelper';
import { getContentfulContent, getAlertIcon, getLabelIcon } from './constants';

/**
 * A clickable title and icon that triggers an alert
 * with a message from Contentful or custom text.
 *
 * Either `contentfulName` or `message` must be provided.
 *
 * Icons types are: "help", "info", "success", "warning", "error"
 *
 * @param {string} props.label - The label for the toolTip (e.g. "Help").
 * @param {string} props.labelIconType - The icon type to display next to the label (e.g. "help", "info", "success").
 * @param {string} [props.contentfulName] - The name of the Contentful entry to retrieve. Required if `message` is not provided.
 * @param {string} [props.message] - A custom message to display if not using Contentful. Required if `contentfulName` is not provided.
 * @param {string} props.alertSeverity - The severity of the alert (e.g. "info").
 * @param {string} props.alertTitle - The title of the alert (e.g. "How to...").
 * @param {string} props.variant - The variant of the alert (e.g. "outlined", "filled").
 */

export const AlertToolTip = ({
  label, labelIconType, contentfulName, message, alertSeverity, alertTitle, variant,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [toolTipContent, setToolTipContent] = useState('');

  if (!contentfulName && !message) {
    console.error('No contentfulName or message provided');
    return null;
  }

  const contentfulContent = async () => {
    const id = await getToolTipID(contentfulName);
    const content = await getContentfulContent(id);
    if (content) setToolTipContent(content);
  };

  useEffect(() => {
    if (contentfulName) {
      contentfulContent();
    } else if (message) {
      setToolTipContent(message);
    }
  }, []);

  const LabelIconComponent = getLabelIcon(labelIconType);

  return (
    <>
      <Typography
        variant="h6"
        style={{
          display: 'flex', gap: '0.25rem', alignItems: 'center', margin: '0.5rem 0', cursor: 'pointer', fontWeight: 600,
        }}
      >
        {label || 'Label Text'}

        <LabelIconComponent
          onClick={() => setShowTooltip(!showTooltip)}
          style={{ fontSize: '1.5rem', cursor: 'pointer', color: !showTooltip ? 'var(--accent)' : '' }}
        />
      </Typography>

      {showTooltip && toolTipContent && (
        <Alert
          severity={alertSeverity || 'info'}
          icon={alertTitle && alertSeverity ? getAlertIcon(alertSeverity) : false}
          variant={variant || 'standard'}
        >
          {alertTitle && (
            <AlertTitle style={{ fontWeight: 600 }}>{alertTitle}</AlertTitle>
          )}

          {contentfulName
            ? <ReactMarkdown>{toolTipContent}</ReactMarkdown>
            : toolTipContent.split('\n').map((line) => (
              <>
                {line}
                <br />
              </>
            ))}
        </Alert>
      )}
    </>
  );
};
