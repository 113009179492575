/* eslint-disable react/destructuring-assignment */
import React from 'react';
import moment from 'moment-timezone';
import MaterialTable, { MTableToolbar } from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import PropTypes from 'prop-types';
import IdTag from '@pitchbooking-dev/pb-shared/lib/components/IdTag';
import { generateTotalWithTaxText, invoiceNumberFormatted } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import { ListItemText } from '@material-ui/core';
import { generatePayoutTransactionsDownloaders } from '../../helpers/index';
import ToolTip from '../../components/Tooltip';

const PayoutOrdersTable = (props) => {
  const {
    stripeCompanyId, currencySym, timezone, payout, isOrder,
  } = props;
  let lines = [];
  let stripePayoutId = '';
  if (payout) {
    lines = payout.lines;
    stripePayoutId = payout.stripeId;
  }

  const showInvoiceNumbers = lines.some((line) => line.invoice);
  const showUserNotes = lines.some((line) => line.userNotes);
  const showCostCode = lines.some((line) => line.costCode);

  const stripeLinkBase = `https://dashboard.stripe.com/${stripeCompanyId}/payments/`;
  return (
    <div>
      <br />
      <MaterialTable
        className="table"
        aria-label="simple table"
        data={lines}
        title={isOrder ? 'Transaction Items' : 'Payout Transactions:'}
        icons={{
          NextPage: () => <ChevronRight />,
          PreviousPage: () => <ChevronLeft />,
          Search: () => <Search />,
          Export: () => <SaveAlt />,
          ResetSearch: () => <Clear />,
          SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
        }}
        columns={[
          {
            title: 'Item',
            field: 'item',
            render: (row) => {
              let itemText = '';
              if (row.item) {
                itemText = row.item;
              } else {
                itemText = payout?.subscriptions?.length > 0
                  ? payout?.subscriptions[0]?.subscriptionName
                  : '';
              }
              if (row.email) {
                itemText += ` (${row.email})`;
              }
              if (row.type === 'refund') { itemText = `Refund: ${itemText}`; }
              if (row.type === 'partial refund') { itemText = `Partial Refund: ${itemText}`; }
              return itemText;
            },
          },
          {
            title: 'Booking Date',
            field: 'bookingDate',
            render: (row) => (
              row.startTime
                ? (
                  <>
                    <div>{moment(row.startTime).tz(timezone).format('ddd D MMM YYYY')}</div>
                    <div>{moment(row.startTime).tz(timezone).format('LT')}</div>
                  </>
                ) : null
            ),
          },
          {
            title: 'Charge ID',
            field: 'chargeId',
            hidden: isOrder,
            render: (row) => (<IdTag title={row.chargeId} url={`${stripeLinkBase}${row.chargeId}`} />),
          },
          {
            title: 'Amount',
            field: 'total',
            render: (row) => (
              <ListItemText
                primary={`${(row.status === 'REFUNDED') ? '-' : ''}${currencySym}${(row.total)?.toFixed(2)}`}
                secondary={`${(row.status === 'REFUNDED') ? '-' : ''}${generateTotalWithTaxText(row, currencySym, true)}`}
              />
            ),

          },
          {
            title: 'Fee',
            field: 'fee',
            render: (row) => (generateTotalWithTaxText({ total: row.fee / 100 }, currencySym)),
          },
          {
            title: 'Invoice Number',
            field: 'invoiceNumber',
            hidden: !showInvoiceNumbers,
            render: ((row) => (row.invoice
              ? invoiceNumberFormatted(row.invoice)
              : '')),
          },
          {
            title: 'Date Processed',
            field: 'paidAt',
            hidden: isOrder,
            render: (row) => (moment(row.paidAt).tz(timezone).format('ddd D MMM YYYY')),
          },
          {
            title: 'Time Processed',
            field: 'paidAt',
            hidden: isOrder,
            render: (row) => (moment(row.paidAt).tz(timezone).format('LT')),
          },
          {
            title: 'Cost Code',
            field: 'costCode',
            hidden: !showCostCode,
            render: (row) => row.costCode,
          },
          {
            title: 'Team Name',
            field: 'teamName',
            render: (row) => row.teamName,
          },
          {
            title: 'User Notes',
            field: 'userNotes',
            hidden: !showUserNotes,
            render: (row) => (row.userNotes ? row.userNotes.value : ''),
          },
        ]}
        options={{
          search: false,
          tableLayout: 'auto',
          exportButton: true,
          emptyRowsWhenPaging: false,
          exportFileName: `Payout ${stripePayoutId} transactions`,
          pageSize: 25,
          pageSizeOptions: [10, 25, 50, 100, 500],
          showFirstLastPageButtons: false,
          showTitle: true,
          headerStyle: {
            backgroundColor: '#efefef',
            fontWeight: 'bold',
          },
          ...generatePayoutTransactionsDownloaders(timezone, stripePayoutId),
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <div style={{ padding: '0px 10px', float: 'right' }}>
                <ToolTip contentfulName="payoutChargeIdTooltip" />
              </div>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        localization={{
          toolbar: {
            exportTitle: 'Download',
          },
        }}
      />
    </div>
  );
};

PayoutOrdersTable.propTypes = {
  payout: PropTypes.shape(),
  stripeCompanyId: PropTypes.string,
  currencySym: PropTypes.string,
  timezone: PropTypes.string,
  isOrder: PropTypes.bool,
};

PayoutOrdersTable.defaultProps = {
  payout: { lines: [] },
  stripeCompanyId: null,
  currencySym: '£',
  timezone: 'Europe/London',
  isOrder: false,
};

export default PayoutOrdersTable;
