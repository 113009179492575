/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import {
  Divider, Typography, Checkbox, Switch,
  Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AlertToolTip } from '../../../../components/AlertToolTip';
import AllowedStartingQuartersPicker from './AllowedStartingQuartersPicker';
import { usePitchbookingUser } from '../../../../hooks';

const convertTimeToMinutes = (time) => {
  if (!time || typeof time !== 'string') {
    return 0; // Default to 0 minutes if invalid
  }
  const [hours, minutes] = time.split(':').map(Number);
  return (hours || 0) * 60 + (minutes || 0);
};

const convertMinutesToTime = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const BookingSlotStartTime = ({
  allowedStartQuarters,
  updateSelectedFacilityStore,
  selectedFacility,
  definedStartTimes,
}) => {
  const { isPitchbookingManager } = usePitchbookingUser();
  const [editDefinedStartTimes, setEditDefinedStartTimes] = useState(
    definedStartTimes?.length > 0 || false,
  );
  const [showAlert, setShowAlert] = useState(false);
  const [alertCustomisation, setAlertCustomisation] = useState({
    severity: '',
    message: '',
  });
  const lastDefinedStartTimes = useRef(null);

  const facilitySchedule = selectedFacility.schedules || [];

  const [checkedTimes, setCheckedTimes] = useState({
    0: [], // Sunday
    1: [], // Monday
    2: [], // Tuesday
    3: [], // Wednesday
    4: [], // Thursday
    5: [], // Friday
    6: [], // Saturday
  });

  const timeSlots = [
    { label: '0 (On the Hour)', value: 0 },
    { label: '15 (15 Minutes)', value: 15 },
    { label: '30 (On the Half Hour)', value: 30 },
    { label: '45 (45 Minutes)', value: 45 },
  ];

  const handleCheckboxChange = (day, time) => {
    setCheckedTimes((prevState) => {
      const newState = { ...prevState };
      const dayTimes = newState[day];

      if (dayTimes.includes(time)) {
        newState[day] = dayTimes.filter((t) => t !== time);
      } else {
        newState[day] = [...dayTimes, time];
      }

      return newState;
    });
  };

  const getSelectedOffsets = () => Object.entries(checkedTimes)
    .filter(([, times]) => times.length > 0)
    .map(([day, times]) => ({
      day: parseInt(day, 10),
      offsets: times,
    }));

  const alignOffset = (startTime, offset) => {
    const startMinutes = startTime % 60;
    return startTime - startMinutes + offset;
  };

  const generateFacilityTimeSlots = (
    scheduleData,
    selectedDay,
    offsets = [],
  ) => {
    const slots = new Set();
    const schedule = scheduleData.find((item) => item.day === selectedDay);
    if (!schedule) return [];

    const { start, end } = schedule;
    const startTime = convertTimeToMinutes(start);
    const endTime = convertTimeToMinutes(end);

    offsets.forEach((offset) => {
      let time = alignOffset(startTime, offset);

      while (time <= endTime) {
        slots.add(
          JSON.stringify({ day: selectedDay, time: convertMinutesToTime(time) }),
        );
        time += 60;
      }
    });

    return [...slots].map((item) => JSON.parse(item));
  };

  const generateSlotData = () => {
    const selectedOffsets = getSelectedOffsets();
    const selectedDays = selectedOffsets.map(({ day }) => day);

    selectedDays.sort((a, b) => (a === 0 ? 1 : b === 0 ? -1 : a - b));

    const slots = [];
    selectedDays.forEach((selectedDay) => {
      const offsets = selectedOffsets.find((offset) => offset.day === selectedDay)?.offsets || [];
      const daySlots = generateFacilityTimeSlots(facilitySchedule, selectedDay, offsets);
      slots.push(...daySlots);
    });

    return slots;
  };

  // Pre-check checkboxes based on definedStartTimes
  useEffect(() => {
    const updatedCheckedTimes = { ...checkedTimes };

    definedStartTimes?.forEach(({ day, time }) => {
      const offset = convertTimeToMinutes(time) % 60; // Get the offset in minutes
      if (!updatedCheckedTimes[day]) updatedCheckedTimes[day] = [];
      updatedCheckedTimes[day].push(offset);
    });

    setCheckedTimes(updatedCheckedTimes);
  }, [definedStartTimes]);

  const checkSelections = () => {
    const allDaysHaveValues = Object.values(checkedTimes).every((times) => times.length > 0);
    const someDaysHaveValues = Object.values(checkedTimes).some((times) => times.length > 0);
    return { allDaysHaveValues, someDaysHaveValues };
  };

  const handleClearSelections = () => {
    setCheckedTimes({
      0: [], // Sunday
      1: [], // Monday
      2: [], // Tuesday
      3: [], // Wednesday
      4: [], // Thursday
      5: [], // Friday
      6: [], // Saturday
    });
  };

  useEffect(() => {
    const { allDaysHaveValues, someDaysHaveValues } = checkSelections();

    if (allDaysHaveValues) {
      const definedStartTimesData = generateSlotData();
      setShowAlert(true);
      setAlertCustomisation({
        severity: 'success',
        message: 'Slot times successfully generated. Click Save to apply changes.',
      });

      // Only update the store if the values have actually changed
      if (JSON.stringify(lastDefinedStartTimes.current) !== JSON.stringify(definedStartTimesData)) {
        updateSelectedFacilityStore({ definedStartTimes: definedStartTimesData });
        lastDefinedStartTimes.current = definedStartTimesData;
      }
    } else if (someDaysHaveValues) {
      setShowAlert(true);
      setAlertCustomisation({
        severity: 'warning',
        message: 'Please select at least one time slot for each day to prevent calendar availability issues.',
      });
    } else {
      setShowAlert(true);
      setAlertCustomisation({
        severity: 'info',
        message: 'No time slots selected. The config from \'Apply to all days\' section will be applied.',
      });

      // Only update the store if it hasn't already been cleared
      if (lastDefinedStartTimes.current !== null) {
        updateSelectedFacilityStore({ definedStartTimes: [] });
        lastDefinedStartTimes.current = null;
      }
    }
  }, [checkedTimes]);

  const preselectDefinedStartTimes = (definedStartTimes) => {
    const updatedCheckedTimes = {
      0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [],
    };

    definedStartTimes?.forEach(({ day, time }) => {
      const offset = convertTimeToMinutes(time) % 60;
      updatedCheckedTimes[day]?.push(offset);
    });

    return updatedCheckedTimes;
  };

  useEffect(() => {
    setCheckedTimes(preselectDefinedStartTimes(definedStartTimes));
  }, [definedStartTimes]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        margin: '1rem 0',
        padding: '0.5rem',
        width: '100%',
      }}
    >
      <AlertToolTip
        label="Booking Slot Start Time"
        labelIconType="help"
        alertSeverity="info"
        message={`For your facility's Booking Slot Start Times, select when bookings can begin within each hour.
          This setting determines the available start times for reservations.
            0 – On the hour (e.g., 10:00, 11:00)
            15 – 15 minutes past the hour (e.g., 10:15, 11:15)
            30 – On the half-hour (e.g., 10:30, 11:30)
            45 – 45 minutes past the hour (e.g., 10:45, 11:45)
          Choose the appropriate option to match your facility’s scheduling preferences and ensure a smooth booking process.`}
      />

      {isPitchbookingManager && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">Customise Defined Start Times</Typography>
          <Switch
            checked={editDefinedStartTimes}
            onChange={() => setEditDefinedStartTimes(!editDefinedStartTimes)}
          />
        </div>
      )}

      {/* Alert displayed above the grid */}
      {showAlert && editDefinedStartTimes && isPitchbookingManager && (
        <Alert severity={alertCustomisation.severity}>
          {alertCustomisation.message}
        </Alert>
      )}

      <div style={{ display: 'flex', gap: '0.75rem', alignItems: 'center' }}>
        <div style={{ minWidth: 'max-content' }}>
          <Typography variant="body1">Apply to all days</Typography>
          {definedStartTimes?.length > 0 && (
            <>
              <Typography variant="body2" style={{ color: 'var(--error)' }}>
                Custom start times have been set for bookings.
              </Typography>
              <Typography variant="body2" style={{ color: 'var(--error)' }}>
                To make any changes, please contact your Account Manager.
              </Typography>
            </>
          )}
          <AllowedStartingQuartersPicker
            defaultValue={allowedStartQuarters}
            updateSelectedFacilityStore={updateSelectedFacilityStore}
            selectedFacility={selectedFacility}
            isDisabled={definedStartTimes?.length > 0}
          />
        </div>

        {isPitchbookingManager && editDefinedStartTimes && (
          <>
            <Divider orientation="vertical" flexItem />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                flex: 1,
                marginLeft: '1rem',
              }}
            >
              {/* First row with 4 days */}
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  gap: '0.5rem',
                }}
              >
                {[
                  { day: 'Monday', value: 1 },
                  { day: 'Tuesday', value: 2 },
                  { day: 'Wednesday', value: 3 },
                  { day: 'Thursday', value: 4 },
                ].map(({ day, value }) => (
                  <div
                    key={day}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '0.5rem',
                      border: '1px solid #ddd',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography variant="body1">{day}</Typography>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {timeSlots.map(({ label, value: slotValue }) => (
                        <div key={slotValue} style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            checked={checkedTimes[value]?.includes(slotValue)}
                            onChange={() => handleCheckboxChange(value, slotValue)}
                          />
                          <Typography variant="caption">{label}</Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              {/* Second row with 3 days, centred */}
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: '0.5rem',
                  justifyContent: 'center',
                }}
              >
                {[
                  { day: 'Friday', value: 5 },
                  { day: 'Saturday', value: 6 },
                  { day: 'Sunday', value: 0 },
                ].map(({ day, value }) => (
                  <div
                    key={day}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '0.5rem',
                      border: '1px solid #ddd',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography variant="body1">{day}</Typography>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {timeSlots.map(({ label, value: slotValue }) => (
                        <div key={slotValue} style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            checked={checkedTimes[value]?.includes(slotValue)}
                            onChange={() => handleCheckboxChange(value, slotValue)}
                          />
                          <Typography variant="caption">{label}</Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <Button
                onClick={handleClearSelections}
                variant="outlined"
                a
                style={{ color: 'var(--error)', border: '1px solid rgba(172, 55, 47, 0.5)' }}
              >
                Clear Selections
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
